.home-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
}

.section-one {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    height: auto;
    background-color: #E8F4FA;
    overflow-x: none;
}

.element-right {
    position: absolute;
    right: -20%;
    bottom: 25%;
    width: 461px;
    height: 352px;
    background-color: #48A0D8;
    border-radius: 33% 67% 47% 53% / 40% 34% 66% 60%;
    transform: rotate(-200deg);
}

.element-left {
    position: absolute;
    bottom: -12%;
    left: -12%;
    width: 280px;
    height: 200px;
    background-color: #08D3BB;
    border-radius: 33% 67% 44% 56% / 58% 21% 79% 42%;
    transform: rotate(-200deg);
}

.block {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 80%;
    height: 75vh;
}

.section-one-text-container {
    position: relative;
    width: 550px;
    height: 400px;
    /* border: 1px solid red; */
    z-index: 1;
}

.shadow-box {
    position: absolute;
    top: 5%;
    left: -20%;
    width: 250px;
    height: 250px;
    background-color: #bba3e067;
    border-radius: 50%;
    filter: blur(2.5rem);
    z-index: -1;
}

.section-one-text-container h1 {
    font-weight: 700;
    font-size: 36px;
}

.section-one-text-container h1 span {
    color: #48A0D8;
}

.section-one-text-container p {
    font-size: 17px;
}

.section-one-btns {
    display: flex;
    align-items: center;
    justify-content: start;
    width: 100%;
    height: 120px;
    /* border: 1px solid green; */
}

.section-one-btns a {
    text-decoration: none;
    text-align: center;
    width: 150px;
    padding: 15px;
    font-size: 16px;
    font-weight: 400;
    color: white;
    background-color: #48A0D8;
    border-radius: 8px;
}

.section-one-btns a:hover {
    background-color: transparent;
    border: 1px solid #48A0D8;
    transition: .7s ease;
    color: #212121;
}

.section-one-btns a:nth-child(2) {
    margin-left: 35px;
    background-color: transparent;
    border: 1px solid #48A0D8;
    color: #212121;
}

.section-one-btns a:nth-child(2):hover {
    background-color: #48A0D8;
    border: none;
    color: white;
}

.section-one-image-container {
    position: relative;
    width: 600px;
    height: 600px;
    /* border: 1px solid red; */
}

.bg-circel {
    position: absolute;
    top: 15%;
    left: 20%;
    width: 300px;
    height: 300px;
    background-color: #F8FAE9;
    filter: blur(2.5rem);
}

.anime_user_image {
    position: absolute;
    top: -20%;
    width: 509px;
    height: 650px;
    background-image: url('../imgs/anime_user.png');
    background-repeat: no-repeat;
    background-size: contain;
    /* border: 1px solid blue; */
}

.tg-bot-icon {
    width: 190px;
    height: 150px;
    position: absolute;
    top: 25px;
    right: 10px;
    background-image: url('../imgs/tg_bot.png');
    background-repeat: no-repeat;
    background-size: cover;
    animation: iconAnimation 3s ease-in infinite;
    animation-direction: alternate;
    z-index: 1;
}

.laptop-icon {
    width: 180px;
    height: 180px;
    position: absolute;
    top: 15px;
    background-image: url('../imgs/comp.png');
    background-repeat: no-repeat;
    background-size: cover;
    animation: laptopAnimation 3s ease-in infinite;
    animation-direction: alternate;
}

.vs-code-icon {
    width: 100px;
    height: 100px;
    position: absolute;
    right: 15px;
    bottom: 55px;
    background-image: url('../imgs/vs_code.png');
    background-repeat: no-repeat;
    background-size: cover;
    animation: vsCodeAnimation 3s ease-in infinite;
    animation-direction: alternate;
}

/* icon animation */
@keyframes iconAnimation {
    0% {
        transform: translateY(0);
        transition: 1s all;
    }

    100% {
        transform: translateY(-30px);
        transition: 1s all;
    }
}

@keyframes laptopAnimation {
    0% {
        transform: translateX(0);
        transition: 1s all;
    }

    100% {
        transform: translateX(-40px);
        transition: 1s all;
    }
}

@keyframes vsCodeAnimation {
    0% {
        transform: translateX(0);
        transition: 1s all;
    }

    100% {
        transform: translateX(40px);
        transition: 1s all;
    }
}

.block-bottom {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 150px;
    border-bottom: 1px solid #C7C7C7;
}

.block-bottom-card {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 400px;
    height: 95px;
    /* border: 1px solid red; */
}

.bottom-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    width: 310px;
    height: 90px;
    padding-left: 15px;
}

.bottom-text h3 {
    font-size: 18px;
}

.bottom-text p {
    font-size: 15px;
}

.bottom-card {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 83px;
    height: 83px;
    background-color: #EFEAFF;
    border-radius: 20px;
}

.block-bottom-card:nth-child(2) .bottom-card {
    background-color: #ECFFDA;
}

.block-bottom-card:nth-child(3) .bottom-card {
    background-color: #DAE6FF;
}

.block-bottom-card:nth-child(4) .bottom-card {
    background-color: #FFE5DA;
}

.bottom-card svg {
    font-size: 32px;
    color: #BD80F5;
}

.block-bottom-card:nth-child(2) .bottom-card svg {
    color: #08D3BB;
}

.block-bottom-card:nth-child(3) .bottom-card svg {
    color: #48A0D8;
}

.block-bottom-card:nth-child(4) .bottom-card svg {
    color: #AD571E;
}

/* Section Two */
.section-two {
    width: 100%;
    height: auto;
    background-color: white;
}

.section-block {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0px auto;
    width: 100%;
    height: auto;
    margin: 150px auto;
    /* border: 1px solid red; */
}

.block-text-cont {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 40%;
    height: 125px;
    /* border: 1px solid green; */
}

.block-text-cont h2 {
    font-size: 24px;
    color: #252525;
}

.block-text-cont p {
    text-align: center;
    font-size: 17px;
    color: #868686;
}

.block-about-one {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 600px;
    /* border: 1px solid blue; */
}

.block-about-one .one {
    position: absolute;
    left: 15%;
    width: 550px;
    height: 370px;
    /* border: 1px solid red; */
}

.block-about-one .block-flex,
.block-about-two .block-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.block-about-one .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 58px;
    height: 58px;
    border-radius: 50%;
    background-color: #08D3BB;
}

.block-about-one .icon .icon-image {
    width: 35px;
    height: 35px;
    background-image: url('../imgs/happy.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.block-about-one .title {
    width: 85%;
    display: flex;
    flex-direction: column;
}

.block-about-one .title h3 {
    font-size: 20px;
    font-weight: 500;
}

.block-about-one .title p {
    font-size: 15px;
    color: #868686;
}

.block-about-one .title-txt {
    width: 90%;
    height: auto;
    margin-top: 25px;
    /* border: 1px solid red; */
}

.block-about-one .title-txt h2 {
    font-size: 30px;
    font-weight: 500;
    color: #252525;
}

.block-about-one .title-txt h2 b {
    color: #48A0D8;
}

.block-about-one .title-desc {
    width: 80%;
    height: 141px;
    margin-top: 25px;
    /* border: 1px solid red; */
}

.block-about-one .title-desc h4 {
    font-size: 16px;
    font-weight: 400;
    color: #868686;
    text-align: unset;
}

.line {
    position: absolute;
    /* bottom: -120%; */
    top: 13%;
    left: 12%;
    width: 791px;
    height: 1308px;
    background-image: url('../imgs/Vector.png');
    background-repeat: no-repeat;
    background-size: cover;
    /* border: 1px solid red; */
}

.block-about-one .one-image {
    width: 738px;
    height: 511px;
    position: absolute;
    right: 12%;
    /* border: 1px solid blue; */
    background-image: url('../imgs/phone.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.block-about-one .shadow-block {
    position: absolute;
    right: -15%;
    width: 497px;
    height: 505px;
    /* transform: rotate(-25deg); */
    background-image: url('../imgs/Ellipse.png');
    background-repeat: no-repeat;
    /* background-size: cover; */
}

.block-about-two {
    position: relative;
    width: 100%;
    height: 700px;
    /* border: 1px solid red; */
}

.block-about-two .one-image {
    width: 600px;
    height: 545px;
    margin-top: 120px;
    margin-left: 155px;
    background-image: url('../imgs/website.png');
    background-repeat: no-repeat;
}

.block-about-two .shadow-block {
    position: absolute;
    top: 50px;
    left: 55px;
    width: 497px;
    height: 505px;
    background-image: url('../imgs/Ellipse2.png');
    background-repeat: no-repeat;
    z-index: -1;
}

.block-about-two .one {
    position: absolute;
    top: 30%;
    right: 21.5%;
    width: 516px;
    height: 389px;
    /* border: 1px solid blue; */
}

.block-about-two .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 58px;
    height: 58px;
    border-radius: 50%;
    background-color: #1090CB;
}

.block-about-two .icon .icon-image {
    width: 35px;
    height: 35px;
    background-image: url('../imgs/happy.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.block-about-two .title {
    width: 85%;
    display: flex;
    flex-direction: column;
}

.block-about-two .title h3 {
    font-size: 20px;
    font-weight: 500;
}

.block-about-two .title p {
    font-size: 15px;
    color: #868686;
}

.block-about-two .title-txt {
    width: 90%;
    height: auto;
    margin-top: 25px;
    /* border: 1px solid red; */
}

.block-about-two .title-txt h2 {
    font-size: 30px;
    font-weight: 500;
    color: #252525;
}

.block-about-two .title-txt h2 b {
    color: #48A0D8;
}

.block-about-two .title-desc {
    width: 80%;
    height: 141px;
    margin-top: 25px;
    /* border: 1px solid red; */
}

.block-about-two .title-desc h4 {
    font-size: 16px;
    font-weight: 400;
    color: #868686;
    text-align: unset;
}

.block-about-three {
    position: relative;
    width: 100%;
    height: 600px;
    /* border: 1px solid red; */
}

.block-about-three .one {
    position: absolute;
    top: 21%;
    left: 13%;
    width: 516px;
    height: 389px;
}

.block-about-three .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 58px;
    height: 58px;
    margin-right: 20px;
    border-radius: 50%;
    background-color: #BD80F5;
}

.block-about-three .icon .icon-image {
    width: 35px;
    height: 35px;
    background-image: url('../imgs/happy.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.block-about-three .block-flex {
    display: flex;
    align-items: center;
    /* justify-content: ; */
}

.block-about-three .title {
    width: 85%;
    display: flex;
    flex-direction: column;
}

.block-about-three .title h3 {
    font-size: 20px;
    font-weight: 500;
}

.block-about-three .title p {
    font-size: 15px;
    color: #868686;
}

.block-about-three .title-txt {
    width: 90%;
    height: auto;
    margin-top: 25px;
    /* border: 1px solid red; */
}

.block-about-three .title-txt h2 {
    font-size: 30px;
    font-weight: 500;
    color: #252525;
}

.block-about-three .title-txt h2 b {
    color: #48A0D8;
}

.block-about-three .title-desc {
    width: 80%;
    height: 141px;
    margin-top: 25px;
    /* border: 1px solid red; */
}

.block-about-three .title-desc h4 {
    font-size: 16px;
    font-weight: 400;
    color: #868686;
    text-align: unset;
}

.block-about-three .one-image {
    position: absolute;
    top: 10%;
    right: 10%;
    width: 901px;
    height: 470px;
    background-image: url('../imgs/3d_user.png');
    background-repeat: no-repeat;
    z-index: 1;
}

.block-about-three .shadow-block {
    position: absolute;
    top: 10%;
    right: -30px;
    width: 497px;
    height: 505px;
    background-image: url('../imgs/Ellipse3.png');
    background-repeat: no-repeat;
    /* border: 1px solid red; */
}


/* Section-Three */
.section-three {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 85vh;
    /* border: 1px solid red; */
}

.about-service {
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 90%;
    height: 600px;
    /* border: 1px solid blue; */
}

.one-card,
.two-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 572px;
    height: 459px;
    padding: 65px;
    border-radius: 20px;
    background-color: #F4F4F4;
}

.one-card h2 {
    text-align: center;
    font-size: 32px;
}

.one-card p {
    text-align: center;
    font-size: 16px;
    margin-top: 35px;
}

.one-card a {
    text-align: center;
    text-decoration: none;
    width: 150px;
    padding: 15px;
    margin-top: 25px;
    background-color: #1090CB;
    border-radius: 10px;
    font-size: 16px;
    color: white;
}

.two-card {
    position: relative;
    width: 572px;
    height: 459px;
    border-radius: 20px;
    background-color: #1090CB;
}

.two-card .ball {
    position: absolute;
    top: -75px;
    right: 35px;
    width: 120px;
    height: 140px;
    transform: rotate(5deg);
    /* border: 1px solid red; */
    background-image: url('../imgs/BellAngle.png');
    background-repeat: no-repeat;
    background-size: auto;
}

.two-card h2 {
    text-align: center;
    font-size: 32px;
    color: white;
}

.two-card p {
    text-align: center;
    font-size: 16px;
    margin-top: 35px;
    color: white;
}

.two-card a {
    text-align: center;
    text-decoration: none;
    width: 150px;
    padding: 15px;
    margin-top: 25px;
    background-color: white;
    border-radius: 10px;
    font-size: 16px;
    color: #1090CB;
}


.card-bg-shadow {
    position: absolute;
    top: 30%;
    right: -10%;
    width: 497px;
    height: 505px;
    background-image: url('../imgs/Ellipse4.png');
    background-repeat: no-repeat;
    z-index: -1;
}

.company-container {
    width: 100%;
    height: 450px;
    position: relative;
    top: 0;
    /* border: 1px solid red; */
}

.company-cont-title h2 {
    font-size: 32px;
    font-weight: 500;
    color: #252525;
}

.company-bg-shadow {
    position: absolute;
    top: 0px;
    left: -90px;
    width: 487px;
    height: 407px;
    background-image: url('../imgs/Ellipse2.png');
    background-repeat: no-repeat;
    z-index: -1;
    /* border: 1px solid red; */
}

.company-cards {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    height: 150px;
    /* border: 1px solid blue; */
    z-index: 1;
}

.company-card {
    width: 220px;
    height: 70px;
    background-color: #08D3BB;
    border-radius: 10px;
}

.scroll-top {
    position: absolute;
    right: 50px;
    bottom: 0;
    width: 65px;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1090CB;
    border-radius: 50%;
    display: none;
}

.scroll-top svg {
    font-size: 20px;
    color: white;
}

.activeScrollTop {
    position: absolute;
    right: 50px;
    bottom: 70px;
    width: 65px;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1090CB;
    border-radius: 50%;
    display: flex;
    position: fixed;
    animation-name: scrolltop;
    animation-duration: 1s;
    cursor: pointer;
}

.activeScrollTop svg {
    font-size: 20px;
    color: white;
}

@keyframes scrolltop {
    0% {
        bottom: -20px;
    }

    100% {
        bottom: 70px;
    }
}

/* LOADER */
.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    z-index: 9999;
}


.lds-ripple,
.lds-ripple div {
    box-sizing: border-box;
}

.lds-ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-ripple div {
    position: absolute;
    border: 4px solid #1090CB;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
}

@keyframes lds-ripple {
    0% {
        top: 36px;
        left: 36px;
        width: 8px;
        height: 8px;
        opacity: 0;
    }

    4.9% {
        top: 36px;
        left: 36px;
        width: 8px;
        height: 8px;
        opacity: 0;
    }

    5% {
        top: 36px;
        left: 36px;
        width: 8px;
        height: 8px;
        opacity: 1;
    }

    100% {
        top: 0;
        left: 0;
        width: 80px;
        height: 80px;
        opacity: 0;
    }
}