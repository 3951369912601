footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    /* border: 1px solid red; */
}

.footer-contact {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 534px;
    background-color: #E8F4FA;
}


.footer-contact-block {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 350px;
    /* border: 1px solid red; */
}

.start {
    position: absolute;
    top: -135px;
    left: -105px;
    width: 70px;
    height: 76px;
    background-image: url('../imgs/Star.png');
    background-repeat: no-repeat;
}

.pairChart {
    position: absolute;
    top: 25px;
    right: 0;
    width: 54px;
    height: 54px;
    background-image: url('../imgs/Pai_Chart.png');
    background-repeat: no-repeat;
}

.footer-contact-block h2 {
    margin-bottom: 25px;
    font-size: 32px;
    font-weight: 500;
    color: #252525;
}


.contact-block {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 100px;
    /* border: 1px solid blue; */
}

.contact-block input {
    width: 350px;
    padding: 25px;
    outline: none;
    border: none;
    border-radius: 7px;
    font-size: 16px;
}

.contact-block button {
    width: 150px;
    height: 45px;
    margin-left: 25px;
    border: none;
    background-color: #252525;
    border-radius: 10px;
    font-size: 16px;
    color: white;
    cursor: pointer;
}

.footer-bottom {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 450px;
    border-bottom: 1px solid silver;
}

.footer-company-about {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 450px;
    height: 200px;
    /* border: 1px solid red; */
}

.footer-company-logo {
    width: 200px;
    height: 100px;
    background-image: url('../imgs/logo.png');
    background-repeat: no-repeat;
    background-size: contain;
}

.footer-home {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 200px;
    height: 200px;
    /* border: 1px solid blue; */
}

.footer-home h3, .footerContact h3 {
    font-size: 18px;
    font-weight: 600;
    color: #252525;
}

.footer-home a, .footerContact a {
    text-decoration: none;
    font-weight: 400;
    color: #252525;
}

.footerContact {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 450px;
    height: 200px;
    /* border: 1px solid blueviolet; */
}

.footer-message {
    display: flex;
    align-items: end;
    justify-content: space-around;
    width: 250px;
    height: 200px;
    /* border: 1px solid green; */
}

.message-card {
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 34px;
    height: 34px;
    background-color: white;
    box-shadow: 2px 3px 4px silver;
    border-radius: 50%;
    color: #252525;
}

.footer-border {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80px;
}

.footer-border p {
    font-size: 14px;
}