@media only screen and (width < 467px) {
    html {
        overflow-x: hidden;
    }

    .home-container {
        width: 100%;
    }

    .section-one {
        width: 100%;
        height: auto;
        overflow-x: none;
    }

    .block {
        width: 95%;
        height: auto;
        margin: 0px auto;
        padding: 15px;
        flex-direction: column-reverse;
    }

    .anime_user_image {
        width: 100%;
        height: 500px;
        margin-top: 90px;
        z-index: 2;
    }

    .bg-circel {
        width: 100%;
        display: none;
    }

    .section-one-image-container {
        width: 100%;
        display: flex;
        align-items: center;
    }

    .element-right,
    .element-left {
        display: none;
    }

    .laptop-icon {
        width: 170px;
        height: 150px;
        left: 0;
        top: 20%;
    }

    .tg-bot-icon {
        width: 180px;
        right: 0;
    }

    .vs-code-icon {
        width: 100px;
    }

    .section-one-text-container {
        width: 100%;
        height: auto;
    }

    .section-one-text-container h1 {
        font-size: 30px;
    }

    .section-one-text-container p {
        font-size: 15px;
    }

    .section-one-btns a {
        width: 140px;
        padding: 13px;
        font-size: 15px;
    }

    .block-bottom {
        width: 100%;
        height: auto;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        margin-bottom: 15px;
    }

    .bottom-card {
        width: 75px;
        height: 75px;
    }

    .block-bottom-card {
        width: 100%;
        padding: 15px;
    }

    .block-bottom-card .bottom-text {
        width: 73%;
    }

    .block-bottom-card .bottom-text h3 {
        font-size: 17px;
    }

    .block-bottom-card .bottom-text p {
        font-size: 14px;
    }

    /* Section Two */
    .section-two {
        position: relative;
        height: auto;
        overflow-x: hidden;
    }

    .block-text-cont {
        width: 90%;
        height: auto;
        margin-top: -90px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .block-text-cont h2 {
        text-align: center;
        font-size: 20px;
        margin-bottom: 10px;
    }

    .block-text-cont p {
        text-align: center;
    }

    .block-about-one {
        position: relative;
        height: auto;
        display: flex;
        flex-direction: column-reverse;
        /* border: 1px solid red; */
    }

    .line {
        display: none;
    }

    .block-about-one .one,
    .block-about-two .one,
    .block-about-three .one {
        width: 100%;
        height: auto;
        padding: 20px;
        position: initial;
        margin-top: 50px;
    }

    .one .block-flex {
        width: 100%;
    }

    .one .block-flex .title {
        width: 80%;
        height: auto;
        margin-top: 25px;
    }

    .one .block-flex .title h3 {
        font-size: 16px;
        font-weight: 600;
    }

    .one .block-flex .title p {
        font-size: 14px;
    }

    .one .title-txt,
    .title-desc {
        width: 100%;
        height: auto;
    }

    .one .title-txt h2 {
        font-size: 25px;
    }

    .one .title-desc h4 {
        font-size: 14px;
        font-weight: 300;
    }

    .block-about-one .one-image {
        width: 90%;
        height: 200px;
        position: initial;
        background-size: contain;
    }


    .block-about-one .shadow-block {
        position: absolute;
        width: 150px;
        height: 250px;
        right: -30px;
        top: 0;
        background-size: contain;
        z-index: 1;

    }

    .block-about-two {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .block-about-two .one {
        position: initial;
    }

    .block-about-two .one-image {
        position: initial;
        width: 300px;
        height: 300px;
        margin-left: -20px;
        margin-top: -20px;
        background-size: contain;
        z-index: 2;
    }

    .block-about-two .shadow-block {
        width: 300px;
        height: 270px;
        top: 20px;
        left: 0;
        background-size: contain;
        z-index: 1;
    }

    .block-about-three {
        position: relative;
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }

    .block-about-three .one-image {
        position: initial;
        width: 100%;
        height: 400px;
        margin-left: -150px;
        background-size: cover;
    }

    .block-about-three .shadow-block {
        position: absolute;
        width: 300px;
        height: 350px;
        top: -75px;
        right: -5px;
        background-size: contain;
    }

    /* Section Three */
    .section-three {
        height: auto;
        overflow-x: none;
    }

    .about-service {
        position: relative;
        width: 90%;
        height: auto;
        margin-top: -105px;
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }

    .one-card,
    .two-card {
        width: 100%;
        height: auto;
        padding: 25px;
        margin-bottom: 25px;
        z-index: 2;
    }

    .two-card .ball {
        top: -100px;
        right: 15px;
    }

    .two-card h2,
    .one-card h2 {
        font-size: 25px;
    }

    .two-card p,
    .one-card p {
        font-size: 15px;
    }

    .two-card a,
    .one-card a {
        padding: 13px;
        font-size: 15px;
    }

    .card-bg-shadow {
        width: 80%;
        display: none;
    }

    .company-container {
        width: 100%;
        height: 100px;
    }

    .activeScrollTop {
        width: 50px;
        height: 50px;
        right: 30px;
        bottom: 45px;
        z-index: 999;
    }


}

