@media only screen and (width < 467px) {
    .nav {
        width: 100%;
        height: 60px;
    }
    
    .activeNavbar {
        width: 100%;
        height: 60px;
    }
    
    .logo a img {
        width: 70px;
    }
    
    .logo a span {
        font-size: 17px;
    }
    
    .menu-list {
        display: none;
    }
    
    .menu-mobile {
        width: 180px;
        height: 50px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }
    
    .menu-mobile svg {
        font-size: 17px;
    }
    
    .menu-mobile svg:nth-child(1) {
        color: red;
    }
    
    .menu-mobile svg:nth-child(2) {
        color: #48A0D8;
    }
    
    .menu-mobile svg:nth-child(3) {
        color: #252525;
        font-size: 20px;
    }

    .menu-mobile {
        color: #252525;
        font-size: 20px;
        z-index: 2;
    }

    .modal-nav {
        width: 95%;
        height: 350px;
        /* border: 1px solid red; */
    }

    .modal-menu-list {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: start;
        /* border: 1px solid green; */
        
    }

    .contact-btn {
        margin-left: 45px;
        width: 80px;
        padding: 8px;
    }

    .contact-btn a {
        text-decoration: none;
        text-align: center;
        font-size: 14px;
    }
}