@media only screen and (width < 467px) {
    footer {
        display: flex;
    }

    .footer-contact {
        height: auto;
    }

    .footer-contact-block {
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .start {
        left: 0;
        top: -39px;
    }

    .pairChart {
        top: 0;
    }

    .footer-contact-block h2 {
        text-align: center;
        font-size: 20px;
    }

    .contact-block {
        height: 155px;
        display: flex;
        flex-direction: column;
    }

    .contact-block input {
        padding: 15px;
        font-size: 15px;
    }

    .contact-block button {
        text-align: center;
        width: 120px;
        height: 40px;
        margin: 15px auto;
        font-size: 15px;
    }

    .footer-bottom {
        height: auto;
        padding-left: 20px;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 15px;
    }

    .footer-company-about,
    .footer-home,
    .footerContact,
    .footer-message {
        width: 90%;
    }

    .footer-message {
        height: auto;
        margin-bottom: 25px;
        align-items: start;
        justify-content: space-between;
    }

    .footer-home h3, .footerContact h3 {
        font-size: 16px;
    }

    .footer-home a, .footerContact a {
        font-size: 14px;
    }

    .footer-border {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .footer-border p {
        text-align: center;
        font-size: 14px;
    }
}