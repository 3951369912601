.nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 85px;
    box-shadow: 3px 2px 8px rgba(192, 192, 192, 0.774);
    z-index: 100;
}

.activeNavbar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 85px;
    box-shadow: 3px 2px 8px rgba(192, 192, 192, 0.774);
    z-index: 100;
    position: fixed;
    background-color: #fff;
}

.logo a {
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo a img {
    width: 100px;
}

.logo a span {
    font-size: 22px;
    font-weight: 600;
    margin-left: -15px;
    color: #48A0D8;
}

ul {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9;
}

ul a {
    margin: 15px;
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
    color: #212121;
}

.contact-btn {
    margin-left: 55px;
    width: 120px;
    padding: 10px;
    background-color: #48A0D8;
    border-radius: 8px;
}

.contact-btn a {
    font-size: 16px;
    color: white;
    cursor: pointer;
}

.menu-mobile {
    display: none;
}
